.secondaryColor {
  color: #ff914d;
}

.main {
  background-color: #0d2321;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5% 0 5%;
  justify-content: space-between;
  height: 100%;
}

.textBanner {
  text-align: left;
  color: #ffffff;
  width: 45%;
}

.title {
  font-size: 60px;
  font-weight: 600;
}

.subText {
  font-size: 20px;
  font-weight: 300;
}

.image {
  position: relative;
  width: 600px;
  height: 600px;
  z-index: 10;
}

.imageBanner {
  width: 600px;
  height: 600px;
  position: relative;
  z-index: 1;
}

.imageBanner::before {
  content: '';
  width: 600px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(17, 64, 59, 0.9) 0%,
    rgba(17, 64, 59, 0.7) 30%,
    rgba(17, 64, 59, 0.5) 60%,
    rgba(17, 64, 59, 0.3) 90%,
    rgba(17, 64, 59, 0) 100%
  );
  filter: blur(50px);
  backdrop-filter: blur(50px);
}

ul {
  list-style: none;
}

ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ff914d; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) and (min-height: 0px) {
  .title {
    font-size: 48px;
  }
  .subText {
    font-size: 16px;
  }
  ul li {
    max-width: 80%;
  }
  .textBanner {
    width: 75%;
  }
  .container {
    justify-content: center;
  }
  .image {
    display: none;
  }
  .imageBanner {
    display: none;
  }
  .imageBanner::before {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (min-height: 400px) {
  .title {
    font-size: 48px;
  }
  .subText {
    font-size: 16px;
  }

  .textBanner {
    width: 75%;
  }
  .container {
    justify-content: center;
  }
  .image {
    display: none;
  }
  .imageBanner {
    display: none;
  }
  .imageBanner::before {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (min-height: 500px) {
  .container {
    justify-content: space-between;
  }
  .title {
    font-size: 36px;
  }
  .subText {
    font-size: 12px;
  }
  ul li {
    max-width: auto;
  }
  .image {
    display: block;
    width: 400px;
    height: 400px;
  }

  .imageBanner {
    display: block;
    width: 400px;
    height: 400px;
  }

  .imageBanner::before {
    display: block;
    content: '';
    width: 400px;
    height: 400px;
    filter: blur(35px);
    backdrop-filter: blur(35px);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (min-height: 600px) {
  .title {
    font-size: 48px;
  }
  .subText {
    font-size: 16px;
  }
  .image {
    width: 500px;
    height: 500px;
  }

  .imageBanner {
    width: 500px;
    height: 500px;
  }

  .imageBanner::before {
    content: '';
    width: 500px;
    height: 500px;
    filter: blur(40px);
    backdrop-filter: blur(40px);
  }
}

@media only screen and (min-width: 1200px) and (min-height: 700px) {
  .textBanner {
    width: 45%;
  }
  .title {
    font-size: 60px;
  }
  .subText {
    font-size: 20px;
  }
  .image {
    width: 600px;
    height: 600px;
  }

  .imageBanner {
    width: 600px;
    height: 600px;
  }

  .imageBanner::before {
    width: 600px;
    height: 600px;
    filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}
